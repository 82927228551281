import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import home from "./components/home";
import aboutus from "./components/AboutUs";
import OperationalTeam from "./components/OperationalTeam";
import contact from "./components/Contact.jsx";
import ourStaff from "./components/OurTeam";
import Sitemap from "./components/Sitemap";
import NavbarMenu from "./components/NavbarMenu";
import Footer from "./components/Footer";
import ovulation from "./components/Services/OvulationInduction";
import invitro from "./components/Services/InVitroFertilization";
import intrauterine from "./components/Services/IntrauterineInsemination";
import intracytoplasmic from "./components/Services/IntracytoplasmicSpermInjection";
import vericocele from "./components/Services/Varicocele";
import Videos from "./components/Gallery/Videos";
import Image from "./components/Gallery/Images";
import Blog from "./components/Media/Blogs";
import Blogtest from "./components/Blogs/Blog";
import Blogtest1 from "./components/Blogs/MainBlog";

import Events from "./components/Media/Events";
import Press from "./components/Media/Press";
import Camp from "./components/Media/camp/Camp";
import Faq from "./components/Media/Faq";
import Career from "./components/Careers";
import Treatment from "./components/Treatments";
import FertilityPreservation from "./components/Services/FertilityPreservation";
import GeneticCounsellingandTesting from "./components/Services/GeneticCounsellingandTesting";
import FertilityTestingMen from "./components/Services/FertilityTestingMen";
import FertilityTestingWomen from "./components/Services/FertilityTestingWomen";
import Facility from "./Facility";
import Tesa from "./components/Services/TesaPesa";
import Kavya from "./components/Doctor/Kavya";
// import Srinivas from "./components/Doctor/Srinivas";
import Doctors from "./components/Doctor/Doctors";
import Bangalore from "./components/Doctor/Branches/Bangalore";
import Mysore from "./components/Doctor/Branches/Mysore";
import Ramanagara from "./components/Doctor/Branches/Ramanagara";
import Divayasree from "./components/Doctor/Divyasree";
import Pavithra from "./components/Doctor/Ramanagara/Pavithra.js";
import Tarun from "./components/Doctor/Tarun";
import Vandana from "./components/Doctor/Vandana";
import Chandan from "./components/Doctor/Chandan";
import NibyElackat from "./components/Doctor/NibyElackat";
// import Shekar from "./components/Doctor/Shekar";
import Pramodh from "./components/Doctor/Pramodh";
import Hema from "./components/Doctor/Hema";
import Satis from "./components/Doctor/Satis";
import Widget from "./components/Widget";
import Donor from "./components/Services/Donor";
import Error from "./components/Error";
import AOS from "aos";
import "aos/dist/aos.css";
// Landing Pages
import LandingPage1 from "./components/LandingPages/KcFertilityServices";
import LandingPage1Thank from "./components/LandingPages/KcFertilityServicesThankYou";
import CustomTitle from "./components/CustomTitle";
import DrSeema from "./components/Doctor/DrSeema";
import DrSwathi from "./components/Doctor/DrSwathi";

import Rashmi from "./components/Doctor/Rashmi";
import Lakshmi from "./components/Doctor/Lakshmi";
import Laxman from "./components/Doctor/Laxman";
import Atif from "./components/Doctor/Atif.js"

import ShankarSingh from "./components/Doctor/ShankarSingh";
import Redirection from "./components/Redirection/Redirection";
import Srinath from "./components/Doctor/Srinath.js";
import Hemalatha from "./components/Doctor/Ramanagara/Hemalatha";
import Popup from "./components/PopUp/Popup";
import ThankYou from "./components/LandingPages/ThankYou";
import Views360 from "./components/Media/Views360/Views360";
import BengaluruCenter from "./components/Centers/Bengaluru/Bengaluru";
import MysoreCenter from "./components/Centers/Bengaluru/Mysore";
import RamanagaraCenter from "./components/Centers/Bengaluru/Ramanagara";
import YeshodaMahesh from "./components/Doctor/Ramanagara/YeshodaMahesh";
import Kusuma from "./components/Doctor/Ramanagara/Kusuma";
import LeftSidebar from "./UI/LeftSidebar/LeftSidebar";
import Sivachandra from "./components/Doctor/Ramanagara/Sivachandra";
import BottomBar from "./UI/BottomBar/BottomBar.js";
import appointments from "./components/Appointments/Appointments.js";
import BookAppointment from "./components/BookAppointment/BookAppointment";
function App() {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  let LandingPages = [
    "fertility-hospital-in-Bangalore",
    "/fertility-hospital-in-Bangalore/thank-you",
  ];
  let url = window.location.href;

  let urls = url.split("/").slice(-1);
  function findCommonArrayElements(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }
  let linkCheck = findCommonArrayElements(LandingPages, urls);
  // let newUrl = url.split("/");
  // console.log(newUrl[3].slice(0, 31));
  let newUrl = window.location.href;

  if (newUrl.includes("fertility-hospital-in-Bangalore")) {
    linkCheck = "true";
  }
  return (
    <div className="App">
      <Router>
        {linkCheck ? "" : <NavbarMenu />}
        {/* {linkCheck ? "" : <Widget />} */}
        <BottomBar />
        {/* <LeftSidebar /> */}
        <Redirection />
        <Popup />
        <Switch>
          <Route exact path="/" component={home} />
          <Route exact path="/blogs" component={Blogtest} />
          <Route exact path="/media/blogs/:title/:id" component={Blogtest1} />
          {/* <Route exact path="/blog-test2/:ids" component={Blogtest1} /> */}
          <Route exact path="/about-us" component={aboutus} />
          <Route exact path="/operational-team" component={OperationalTeam} />
          <Route exact path="/contact" component={contact} />
          <Route exact path="/ourstaff" component={ourStaff} />
          <Route exact path="/facility" component={Facility} />
          <Route exact path="/gallery/videos" component={Videos} />
          <Route exact path="/gallery/images" component={Image} />
          <Route exact path="/media/blogs" component={Blogtest} />

          <Route exact path="/media/press" component={Press} />
          <Route exact path="/media/camp" component={Camp} />
          <Route exact path="/media/360-view" component={Views360} />
          <Route exact path="/media/Faq" component={Faq} />
          <Route exact path="/media/Events" component={Events} />
          <Route exact path="/careers" component={Career} />

          {/* //centers  */}
          <Route exact path="/centers/bengaluru" component={BengaluruCenter} />
          <Route exact path="/centers/mysore" component={MysoreCenter} />
          <Route
            exact
            path="/centers/ramanagara"
            component={RamanagaraCenter}
          />

          <Route exact path="/fertility-treatments" component={Treatment} />
          <Route
            exact
            path="/treatments/fertility-preservation"
            component={FertilityPreservation}
          />
          <Route
            exact
            path="/treatments/genetic-counselling-and-testing"
            component={GeneticCounsellingandTesting}
          />
          <Route
            exact
            path="/treatments/ovulation-induction"
            component={ovulation}
          />
          <Route
            exact
            path="/treatments/invitro-fertilization"
            component={invitro}
          />
          <Route path="/treatments/intrauterine" component={intrauterine} />
          <Route
            exact
            path="/treatments/intracytoplasmic"
            component={intracytoplasmic}
          />
          <Route exact path="/treatments/varicocele" component={vericocele} />
          <Route
            exact
            path="/treatments/fertility-testing-for-men"
            component={FertilityTestingMen}
          />
          <Route
            exact
            path="/treatments/fertility-testing-for-women"
            component={FertilityTestingWomen}
          />
          <Route exact path="/treatments/tesa-pesa" component={Tesa} />
          <Route
            exact
            path="/doctors/fertility-specialist-in-bangalore"
            component={Doctors}
          />
          <Route exact path="/doctors/bangalore" component={Bangalore} />
          <Route exact path="/doctors/mysore" component={Mysore} />
          <Route exact path="/doctors/ramanagara" component={Ramanagara} />
          <Route exact path="/doctor/divyasree" component={Divayasree} />
          <Route exact path="/doctor/pavithra-h" component={Pavithra} />
          <Route exact path="/doctor/tarun-javali" component={Tarun} />
          {/* <Route exact path="/doctor/drseema" component={DrSeema} /> */}
          <Route exact path="/doctor/drswathi" component={DrSwathi} />
          <Route exact path="/doctor/rashmi" component={Rashmi} />
          <Route exact path="/doctor/lakshmi" component={Lakshmi} />
          <Route exact path="/doctor/laxman" component={Laxman} />
          <Route exact path="/doctor/atif" component={Atif} />
          {/* <Route exact path="/doctor/vandana" component={Vandana} /> */}
          <Route exact path="/doctor/ramya" component={Kavya} />
          {/* <Route exact path="/doctor/srinivas" component={Srinivas} /> */}
          <Route exact path="/doctor/chandan" component={Chandan} />
          <Route exact path="/doctor/shankar-singh" component={ShankarSingh} />
          <Route exact path="/sitemap" component={Sitemap} />
          {/* <Route exact path="/doctor/niby-j-elackatt" component={NibyElackat} /> */}
          {/* <Route exact path="/doctor/shekar" component={Shekar} /> */}
          <Route exact path="/doctor/pramodh" component={Pramodh} />
          <Route exact path="/doctor/hema" component={Hema} />
          <Route exact path="/doctor/srinath" component={Srinath} />
          <Route exact path="/doctor/hemalatha" component={Hemalatha} />
          <Route
            exact
            path="/doctor/yeshoda-s-mahesh"
            component={YeshodaMahesh}
          />
          <Route exact path="/doctor/dr-kusuma" component={Kusuma} />
          <Route exact path="/doctor/dr-sivachandra" component={Sivachandra} />
          {/* <Route exact path="/doctor/satis" component={Satis} /> */}
          <Route exact path="/treatments/donor" component={Donor} />
          <Route
            exact
            path="/fertility-hospital-in-Bangalore"
            component={LandingPage1}
          />
          <Route exact path="/appointments" component={appointments} />
          <Route
            exact
            path="/book-appointment/:appoitmentBranch"
            component={BookAppointment}
          />
          <Route
            exact
            path="/fertility-hospital-in-Bangalore/thank-you"
            component={LandingPage1Thank}
          />
          <Route exact path="/thank-you" component={ThankYou} />

          <Route component={Error} />
        </Switch>
        {linkCheck ? "" : <Footer />}
      </Router>
    </div>
  );
}

export default App;
